import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const SpacePageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className="py-10 pt-md-11 bg-dark">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 text-center text-white">
              <h6 className="text-uppercase text-warning mb-5">
                Welcome to Reno Collective
              </h6>

              <h2 className="display-3 mb-4">We are gathering again.</h2>

              <p className="font-size-lg">🚀 We are accepting new members!</p>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-10 pt-md-12">
        <div className="container-lg">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 text-center">
              <h6 class="text-uppercase text-primary-light mb-10">
                Get more things done
              </h6>
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <img
                className="img-fluid mb-4 mb-md-0"
                src="/img/collective/work_studio.jpg"
                alt="Reno Collective Coworking"
              />
            </div>
            <div className="col-md-7 col-lg-6">
              <h2 className="display-4 mb-4">Work Studio</h2>
              <p className="text-muted">
                Multiple cafe desks are available in the coworking studio, which
                formerly housed the original recording studio. The studio has
                isolation rooms available for making phone and video calls, and
                is made available for events, classes and meetups organized by
                members.
              </p>
              <div className="row">
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Quiet workspace</li>
                    <li className="mb-3">Phone booths</li>
                    <li>Group tables</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Standup desks available</li>
                    <li>Bookable event space</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-10 pt-md-12 pb-11 pb-md-10">
        <div className="container-lg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5 order-md-1">
              <img
                className="img-fluid mb-8 mb-md-0"
                src="/img/collective/residents.jpg"
                alt="Reno Collective Resident Desks"
              />
            </div>
            <div className="col-md-7 col-lg-6 order-md-0">
              <h2 className="display-4 mb-4">Resident Desks</h2>
              <p className="text-muted">
                Resident desks offer a more permanent home for you and your
                business. Set it up just the way you need to work. Resident
                desks are located on the 2nd and 3rd floors. Team rooms are
                occasionally available upon request.
              </p>
              <div className="row">
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Dedicated desk</li>
                    <li className="mb-3">Private or shared rooms</li>
                    <li>Personalized workspace</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Flexible billing options</li>
                    <li className="mb-3">Desk provided</li>
                    <li>Company logo on the door</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-10 pt-md-12 pb-5 pb-md-5">
        <div className="container-lg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <img
                className="img-fluid mb-4 mb-md-0"
                src="/img/collective/conference_screen.jpg"
                alt="Reno conference rooms"
              />
            </div>
            <div className="col-md-7 col-lg-6">
              <h2 className="display-4 mb-4">Conference Rooms</h2>
              <p className="text-muted">
                Our conference rooms can be easily booked by members to meet
                with clients or team members, hold video calls or host small
                meetups.
              </p>
              <div className="row">
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Reservable</li>
                    <li className="mb-3">Conference table</li>
                    <li>Whiteboards</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Broadcast capability</li>
                    <li className="mb-3">Wired, reliable connection</li>
                    <li>Clients welcome</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pt-md-12">
        <div className="container-lg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5 order-md-1">
              <img
                className="img-fluid mb-8 mb-md-0"
                src="/img/home/phonerooms.jpg"
                alt="Phone Rooms"
              />
            </div>
            <div className="col-md-7 col-lg-6 order-md-0">
              <h2 className="display-4 mb-4">Phone Rooms + Booths</h2>
              <p className="text-muted">
                Just need some privacy for a phone call or daily standup
                meeting? We have phone rooms located throughout the space.
              </p>
              <div className="row">
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Sound dampened</li>
                    <li className="mb-3">Professional backdrop</li>
                    <li>Privacy for calls</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">
                      First-come first-serve & reservable
                    </li>
                    <li className="mb-3">Standup desks available</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

SpacePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const SpacePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SpacePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

SpacePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SpacePage

export const spacePageQuery = graphql`
  query SpacePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
